import axios from "axios";

let HOST = "http://localhost:3000/v2";
if (process.env.NODE_ENV === "production") {
  HOST = "https://indra.humanitas.ai/v2";
}

let DASHBOARD_URL = "http://localhost:3000";
if (process.env.NODE_ENV === "production") {
  DASHBOARD_URL = "https://admin.humanitas.ai";
}

function objectToQueryString(obj) {
  return Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");
}

const API = {
  auth: {
    login: (data) =>
      axios.post(`${HOST}/sessions/sign_in`, data, {
        login: true,
        noAuth: true,
        storeCookies: true,
      }),
    logout: () =>
      axios.delete(`${HOST}/sessions/sign_out`, {
        clearCookies: true,
      }),
    forgotPassword: (data) =>
      axios.post(
        `${HOST}/sessions/forgot_password`,
        {
          ...data,
          redirect_url: `${DASHBOARD_URL}/reset_password`,
        },
        {
          noAuth: true,
        }
      ),
    resetPassword: (data) =>
      axios.post(`${HOST}/sessions/verify_password`, data, {
        noAuth: true,
      }),
    validate: () => axios.get(`${HOST}/sessions/validate_token`),
    getUser: () => axios.get(`${HOST}/sessions`),
    update: (data) => axios.put(`${HOST}/sessions`, data),
  },
  metrics: {
    get: (filter) => axios.get(`${HOST}/admins/metrics?filter=${filter}`),
  },
  orgs: {
    listOrgs: () => axios.get(`${HOST}/admins/orgs_list`),
    getOrgs: (page, text) =>
      axios.get(`${HOST}/admins/orgs?page=${page}&search=${text}`),
    createOrg: (data) => axios.post(`${HOST}/admins/orgs`, data),
    updateOrg: (id, data) => axios.put(`${HOST}/admins/orgs/${id}`, data),
    getOrgDetails: (id) => axios.get(`${HOST}/admins/orgs/${id}`),
    employees: {
      index: (id, page, per, format = "json") =>
        axios.get(
          `${HOST}/admins/orgs/${id}/employees?page=${page}&per=${per}&format=${format}`
        ),
      create: (id, data) =>
        axios.post(`${HOST}/admins/orgs/${id}/employees`, data),
      bulkUpdate: (id, data) =>
        axios.post(`${HOST}/admins/orgs/${id}/employees_bulk`, data),
      terminationUpdate: (id, data) =>
        axios.post(`${HOST}/admins/orgs/${id}/termination`, data),
    },
    gifts: {
      index: (id, page, per) =>
        axios.get(`${HOST}/admins/orgs/${id}/gifts?page=${page}&per=${per}`),
      bulkUpdate: (id, data) =>
        axios.post(`${HOST}/admins/orgs/${id}/gifts_bulk`, data),
    },
    admins: {
      getAdmins: (id) => axios.get(`${HOST}/admins/orgs/${id}/admins`),
      addAdmins: (id, data) => axios.post(`${HOST}/orgs/${id}/admins`, data),
      removeAdmins: (id, adminId) =>
        axios.delete(`${HOST}/orgs/${id}/admins/${adminId}`),
    },
    usage: {
      getUsage: (id, from, to) =>
        axios.get(`${HOST}/orgs/${id}/usage?from=${from}&to=${to}`),
    },
    logs: {
      getLogs: (id, from, to, scope, page) =>
        axios.get(
          `${HOST}/admins/orgs/${id}/logs?from=${from}&to=${to}&scope=${scope}&page=${page}`
        ),
      exportLogs: (id, from, to, scope) =>
        axios.get(
          `${HOST}/admins/orgs/${id}/logs_export?from=${from}&to=${to}&scope=${scope}`
        ),
    },
    donations: {
      getDonations: (id, from, to, page) =>
        axios.get(
          `${HOST}/admins/donations?org_id=${id}&from=${from}&to=${to}&page=${page}`
        ),
    },
    personalize: {
      getPersonalisedNodes: (id, page) =>
        axios.get(`${HOST}/orgs/${id}/nodes?page=${page}`),
    },
  },
  nodes: {
    index: (id) => axios.get(`${HOST}/admins/nodes`),
    show: (id) => axios.get(`${HOST}/admins/nodes/${id}`),
    update: (id, data) => axios.put(`${HOST}/admins/nodes/${id}`, data),
    admins: {
      addAdmins: (id, data) => axios.post(`${HOST}/nodes/${id}/admins`, data),
    },
  },
  services: {
    show: (id) => axios.get(`${HOST}/services/${id}`),
    create: (data) => axios.post(`${HOST}/services`, data),
    update: (id, data) => axios.put(`${HOST}/services/${id}`, data),
    updateBulk: (id, data) =>
      axios.put(`${HOST}/services/${id}/update_bulk`, data),
    delete: (id) => axios.delete(`${HOST}/services/${id}`),
    listBenefitCalculators: () =>
      axios.get(`${HOST}/admins/benefit_calculators_list`),
    getHistory: (id, page, per) =>
      axios.get(`${HOST}/services/${id}/edit_logs?page=${page}&per=${per}`),
    getReviews: (id, page, per) =>
      axios.get(`${HOST}/services/${id}/reviews?page=${page}&per=${per}`),
    getInteractionMetrics: (id) =>
      axios.get(`${HOST}/services/${id}/interaction_metrics`),
    downloadInteractions: (id, start, end) =>
      axios.get(
        `${HOST}/admins/interactions?start=${start}&end=${end}&format=csv${
          id ? `&service_id=${id}` : ""
        }`
      ),
  },
  discover: {
    search: (query, categories, states, page, per) =>
      axios.get(
        `${HOST}/search?query=${query}&categories=${categories}&states=${states}&page=${page}&per=${per}`
      ),
    searchServices: (query, filters, page, per, searchType = "services") =>
      axios.get(
        `${HOST}/search?query=${query}&page=${page}&per=${per}&scope=${searchType}${
          Object.keys(filters).length > 0
            ? `&${objectToQueryString(filters)}`
            : ""
        }`
      ),
    downloadServices: (query, filters, page, per) =>
      axios.get(
        `${HOST}/search?query=${query}&page=${page}&per=${per}&scope=services&format=csv&${
          Object.keys(filters).length > 0
            ? `&${objectToQueryString(filters)}`
            : ""
        }`
      ),
    searchGeography: (query, scope) =>
      axios.get(`${HOST}/search_geography?query=${query}&scope=${scope}`),
    searchSurveys: (query) =>
      axios.get(`${HOST}/search_surveys?query=${query}`),
  },
  users: {
    getUsers: (page, text) =>
      axios.get(`${HOST}/admins/users?page=${page}&search=${text}`),
    download: (start, end) =>
      axios.get(`${HOST}/admins/users?start=${start}&end=${end}&format=csv`),
    show: (id) => axios.get(`${HOST}/admins/users/${id}`),
    update: (id, data) => axios.put(`${HOST}/admins/users/${id}`, data),
    latestConversation: (id) =>
      axios.get(
        `${HOST}/admins/latest_conversation${id ? `?user_id=${id}` : ""}`
      ),
    summary: (convId, userId) =>
      axios.get(
        `${HOST}/admins/summary${convId ? `?conversation_id=${convId}&` : ""}${
          userId ? `user_id=${userId}` : ""
        }`
      ),
    resetSummary: (id) =>
      axios.post(`${HOST}/admins/users/${id}/reset_summary`),
    forceSummary: (id) =>
      axios.post(`${HOST}/admins/users/${id}/force_summary`),
    resetProfile: (id, data) =>
      axios.post(`${HOST}/admins/users/${id}/reset_profile`, data),
    inferences: (id) => axios.get(`${HOST}/admins/users/${id}/inferences`),
    reminders: (id) => axios.get(`${HOST}/admins/users/${id}/reminders`),
    queuedReminders: (id) =>
      axios.get(`${HOST}/admins/users/${id}/reminders?status=1`),
  },
  waitlist: {
    index: (page) => axios.get(`${HOST}/admins/waitlist?page=${page}`),
  },
  conversations: {
    index: (page, search, filters) =>
      axios.post(`${HOST}/admins/conversations?page=${page}&search=${search}`, {
        filters: filters,
      }),
    show: (id) => axios.get(`${HOST}/admins/conversations/${id}`),
    delete: (id) => axios.delete(`${HOST}/admins/conversations/${id}`),
    events: {
      index: (id) => axios.get(`${HOST}/admins/conversations/${id}/events`),
    },
  },
  needs: {
    index: (nodeId, approved) =>
      axios.get(`${HOST}/nodes/${nodeId}/needs?approved=${approved}`),
  },
  data: {
    index: (data) =>
      axios.get(
        `${HOST}/admins/data_sources?${
          data.nodeId ? `node_id=${data.nodeId}` : `user_id=${data.userId}`
        }`
      ),
  },
  chats: {
    index: (id) => axios.get(`${HOST}/conversations/${id}/chats`),
  },
  announcements: {
    index: (page, per) =>
      axios.get(`${HOST}/admins/announcements?page=${page}&per=${per}`),
    download: (start, end) =>
      axios.get(
        `${HOST}/admins/announcements?start=${start}&end=${end}&format=csv`
      ),
    create: (data) => axios.post(`${HOST}/admins/announcements`, data),
  },
  support: {
    index: (page, per, search, filters) =>
      axios.post(
        `${HOST}/admins/support_requests?page=${page}&per=${per}&search=${search}`,
        {
          filters: filters,
        }
      ),
    update: (id, data) =>
      axios.put(`${HOST}/admins/support_requests/${id}`, data),
  },
  surveys: {
    index: (page, per) => axios.get(`${HOST}/surveys?page=${page}&per=${per}`),
    show: (id) => axios.get(`${HOST}/surveys/${id}`),
    create: (data) => axios.post(`${HOST}/surveys`, data),
    update: (id, data) => axios.put(`${HOST}/surveys/${id}`, data),
    delete: (id) => axios.delete(`${HOST}/surveys/${id}`),
    downloadResponses: (id) =>
      axios.get(`${HOST}/surveys/${id}/responses?format=csv`),
  },
  links: {
    index: (page, per) =>
      axios.get(`${HOST}/admins/shortened_links?page=${page}&per=${per}`),
    create: (data) => axios.post(`${HOST}/admins/shortened_links`, data),
    update: (id, data) =>
      axios.put(`${HOST}/admins/shortened_links/${id}`, data),
    delete: (id) => axios.delete(`${HOST}/admins/shortened_links/${id}`),
  },
  workers: {
    getWorkers: () => axios.get(`${HOST}/admins/workers`),
    getFailedWorkers: (queue) =>
      axios.get(`${HOST}/admins/workers_failed?queue=${queue}`),
    casey: {
      getWorkers: () => axios.get(`${HOST}/admins/casey_workers`),
      deleteJob: (worker, jobId) =>
        axios.delete(`${HOST}/admins/casey_workers/${worker}/jobs/${jobId}`),
    },
  },
};

export default API;
